import { Action } from '@lib/plugin-redux-core';
import { AssignmentDetailPageState, AssignmentDetailPageStatus } from './assignment-detail.reducer';

export const handleGetResponderAssignmentUseCase = {
  executing: (state: AssignmentDetailPageState): AssignmentDetailPageState => {
    return {
      ...state,
      getAssignmentStatus: AssignmentDetailPageStatus.EXECUTE,
    };
  },
  success: (state: AssignmentDetailPageState, action: Action): AssignmentDetailPageState => {
    return {
      ...state,
      assignment: action.payload,
      getAssignmentStatus: AssignmentDetailPageStatus.SUCCESS,
    };
  },
  error: (state: AssignmentDetailPageState, action: Action): AssignmentDetailPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      getAssignmentStatus: AssignmentDetailPageStatus.ERROR,
    };
  },
};

export const handleGetSubmissionForResponderUseCase = {
  executing: (state: AssignmentDetailPageState): AssignmentDetailPageState => {
    return {
      ...state,
      getSubmissionsStatus: AssignmentDetailPageStatus.EXECUTE,
    };
  },
  success: (state: AssignmentDetailPageState, action: Action): AssignmentDetailPageState => {
    return {
      ...state,
      submissions: action.payload,
      getSubmissionsStatus: AssignmentDetailPageStatus.SUCCESS,
    };
  },
  error: (state: AssignmentDetailPageState, action: Action): AssignmentDetailPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      getSubmissionsStatus: AssignmentDetailPageStatus.ERROR,
    };
  },
};

export const handleCreateAttendeeUseCase = {
  executing: (state: AssignmentDetailPageState): AssignmentDetailPageState => {
    return {
      ...state,
      createAttendeeStatus: AssignmentDetailPageStatus.EXECUTE,
    };
  },
  success: (state: AssignmentDetailPageState, action: Action): AssignmentDetailPageState => {
    return {
      ...state,
      attendeeId: action.payload.id,
      createAttendeeStatus: AssignmentDetailPageStatus.SUCCESS,
    };
  },
  error: (state: AssignmentDetailPageState, action: Action): AssignmentDetailPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      createAttendeeStatus: AssignmentDetailPageStatus.ERROR,
    };
  },
};

export const handleCreateSubmissionUseCase = {
  executing: (state: AssignmentDetailPageState): AssignmentDetailPageState => {
    return {
      ...state,
      isLoadingCreateSubmission: true,
      createSubmissionStatus: AssignmentDetailPageStatus.EXECUTE,
    };
  },
  success: (state: AssignmentDetailPageState, action: Action): AssignmentDetailPageState => {
    return {
      ...state,
      newSubmissionId: action.payload.id.toString(),
      isLoadingCreateSubmission: false,
      createSubmissionStatus: AssignmentDetailPageStatus.SUCCESS,
    };
  },
  error: (state: AssignmentDetailPageState, action: Action): AssignmentDetailPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      isLoadingCreateSubmission: false,
      createSubmissionStatus: AssignmentDetailPageStatus.ERROR,
    };
  },
};
