import { ParticipatedAssignmentResponder } from "@module/assignment";
import { Subject } from "@module/form";
import { ParticipatedAssignmentState } from "./participated-assignment.reducer";

export type participatedAssignmentSelectorState = {
  participatedAssignmentPage: ParticipatedAssignmentState
}
export const participatedAssignmentResponseSelector = (state: participatedAssignmentSelectorState):
  ParticipatedAssignmentResponder[] =>
    state.participatedAssignmentPage.participatedAssignmentResponse;

export const isParticipatedAssignmentLoadingSelector = (state: participatedAssignmentSelectorState): boolean =>
  state.participatedAssignmentPage.isParticipatedAssignmentLoading;

export const subjectsSelector = (state: participatedAssignmentSelectorState): Subject[] =>
  state.participatedAssignmentPage.subjectResponses;