import {
  CreateEssayAnswerUseCase,
  CreateFillInBlankAnswerUseCase,
  CreateMultipleChoiceAnswerUseCase,
  CreateSingleChoiceAnswerUseCase,
  CreateSubmissionUseCase,
  GetAnswersUseCase,
  GetSubmissionByIdUseCase,
  GetSubmissionsUseCase,
  ISubmissionRepository,
  SUBMISSION_REPOSITORY,
  UpdateEssayAnswerUseCase,
  UpdateFillInBlankAnswerUseCase,
  UpdateMultipleChoiceAnswerUseCase,
  UpdateSingleChoiceAnswerUseCase,
  UpdateSubmissionUseCase,
  CREATE_ESSAY_ANSWER_USE_CASE,
  CREATE_FILL_IN_BLANK_ANSWER_USE_CASE,
  CREATE_MULTIPLE_CHOICE_ANSWER_USE_CASE,
  CREATE_SINGLE_CHOICE_ANSWER_USE_CASE,
  CREATE_SUBMISSION_USE_CASE,
  GET_ANSWERS_USE_CASE,
  GET_SUBMISSIONS_USE_CASE,
  GET_SUBMISSION_BY_ID_USE_CASE,
  UPDATE_ESSAY_ANSWER_USE_CASE,
  UPDATE_FILL_IN_BLANK_ANSWER_USE_CASE,
  UPDATE_MULTIPLE_CHOICE_ANSWER_USE_CASE,
  UPDATE_SINGLE_CHOICE_ANSWER_USE_CASE,
  UPDATE_SUBMISSION_USE_CASE,
  PausePracticeSubmissionUseCase,
  PAUSE_PRACTICE_SUBMISSION_USE_CASE,
  ResumePracticeSubmissionUseCase,
  RESUME_PRACTICE_SUBMISSION_USE_CASE,
  GET_SUBMISSIONS_FOR_RESPONDER_USE_CASE,
  GetSubmissionsResponderUseCase,
  ISubmissionResponderRepository,
  SUBMIT_PRACTICE_SUBMISSION_USE_CASE,
  SubmitPracticeSubmissionsUseCase,
  IAssignmentRepository,
  ASSIGNMENT_REPOSITORY,
} from '@module/assignment';
import { IQuestionReleasesRepository, QUESTION_RELEASES_REPOSITORY } from '@module/form';

export const submissionUseCaseProviders = [
  {
    provide: GET_SUBMISSIONS_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new GetSubmissionsUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  // Below Use case is all responder use cases
  {
    provide: CREATE_SUBMISSION_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new CreateSubmissionUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: UPDATE_SUBMISSION_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new UpdateSubmissionUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: GET_ANSWERS_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new GetAnswersUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: GET_SUBMISSION_BY_ID_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new GetSubmissionByIdUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: CREATE_ESSAY_ANSWER_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new CreateEssayAnswerUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: UPDATE_ESSAY_ANSWER_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new UpdateEssayAnswerUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: CREATE_SINGLE_CHOICE_ANSWER_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new CreateSingleChoiceAnswerUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: UPDATE_SINGLE_CHOICE_ANSWER_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new UpdateSingleChoiceAnswerUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: CREATE_MULTIPLE_CHOICE_ANSWER_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new CreateMultipleChoiceAnswerUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: UPDATE_MULTIPLE_CHOICE_ANSWER_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new UpdateMultipleChoiceAnswerUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: CREATE_FILL_IN_BLANK_ANSWER_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new CreateFillInBlankAnswerUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: UPDATE_FILL_IN_BLANK_ANSWER_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new UpdateFillInBlankAnswerUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: GET_SUBMISSIONS_FOR_RESPONDER_USE_CASE,
    useFactory: (
      submissionRepository: ISubmissionRepository,
      assignmentRepository: IAssignmentRepository,
      questionReleasesRepository: IQuestionReleasesRepository,
    ) => {
      return new GetSubmissionsResponderUseCase(
        submissionRepository,
        assignmentRepository,
        questionReleasesRepository,
      );
    },
    inject: [SUBMISSION_REPOSITORY, ASSIGNMENT_REPOSITORY, QUESTION_RELEASES_REPOSITORY],
  },
  {
    provide: PAUSE_PRACTICE_SUBMISSION_USE_CASE,
    useFactory: (submissionRepository: ISubmissionResponderRepository) => {
      return new PausePracticeSubmissionUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: RESUME_PRACTICE_SUBMISSION_USE_CASE,
    useFactory: (submissionRepository: ISubmissionResponderRepository) => {
      return new ResumePracticeSubmissionUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: SUBMIT_PRACTICE_SUBMISSION_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new SubmitPracticeSubmissionsUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
];
