import {
  AssignmentApi,
  ASSIGNMENT_API,
  ParticipatedAssignmentApi,
  PARTICIPATED_ASSIGNMENT_API,
  PracticeAssignmentApi,
  PracticeSubmissionApi,
  PRACTICE_ASSIGNMENT_API,
  PRACTICE_SUBMISSION_API,
  SubmissionApi,
  SUBMISSION_API,
} from '@module/assignment';
import {
  FORM_RELEASE_API,
  QuestionReleasesApi,
  QUESTION_RELEASES_API,
  SubjectApi,
  SUBJECT_API,
  FormReleaseApi,
} from '@module/form';
import { AuthApi, AUTH_API, UserApi, USER_API } from '@module/user';
import { AxiosInstanceBuilder } from '@smart-form/plugin-axios';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const timeout = publicRuntimeConfig.api.timeout;

export const authApiProvider = {
  provide: AUTH_API,
  useFactory: () => {
    return new AuthApi(
      new AxiosInstanceBuilder()
        .withBaseUrl(publicRuntimeConfig.api.baseIdentityUrl)
        .withTimeout(timeout)
        .toInstance(),
    );
  },
};

export const apiProviders = [
  authApiProvider,
  {
    provide: USER_API,
    useFactory: () => {
      return new UserApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseIdentityUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: FORM_RELEASE_API,
    useFactory: () => {
      return new FormReleaseApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: QUESTION_RELEASES_API,
    useFactory: () => {
      return new QuestionReleasesApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: ASSIGNMENT_API,
    useFactory: () => {
      return new AssignmentApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseAssignmentUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: SUBMISSION_API,
    useFactory: () => {
      return new SubmissionApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseAssignmentUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: PRACTICE_SUBMISSION_API,
    useFactory: () => {
      return new PracticeSubmissionApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseAssignmentUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: PRACTICE_ASSIGNMENT_API,
    useFactory: () => {
      return new PracticeAssignmentApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseAssignmentUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: PARTICIPATED_ASSIGNMENT_API,
    useFactory: () => {
      return new ParticipatedAssignmentApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseAssignmentUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: SUBJECT_API,
    useFactory: () => {
      return new SubjectApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
];
