import { Action } from '@lib/plugin-redux-core';
import {
  AnswerType,
  ConfigType,
  CreateAnswerUseCaseInput,
  CreateEssayAnswerInput,
  CreateFillInBlankAnswerInput,
  CreateMultipleChoiceAnswerInput,
  CreateSingleChoiceAnswerInput,
  EssayAnswer,
  EssayAnswerValue,
  FillInBlankAnswer,
  FillInBlankAnswerValue,
  FillInBlankGroupAnswer,
  MultipleChoiceAnswer,
  SingleChoiceAnswer,
  SingleMultipleChoiceAnswerValue,
  SubmissionStatus,
  SubmissionSummary,
} from '@module/assignment';
import { SubmissionPageState, SubmissionPageStatus } from './submission.reducer';

const onUpdateAnsweredQuestionCached = (
  submissionId: string,
  sectionId: string,
  questionId: string,
) => {
  const answeredQuestionCached = JSON.parse(localStorage.getItem('answered_question')) || [];
  const unique = (arr, keyProps) => {
    const combineArray = arr.map((entry) => {
      const key = keyProps.map((k) => entry[k]).join('|');
      return [key, entry];
    });
    const map = new Map(combineArray);
    return Array.from(map.values());
  };

  localStorage.setItem(
    'answered_question',
    JSON.stringify(
      unique(
        [
          ...answeredQuestionCached,
          {
            submissionId,
            sectionId,
            questionId,
          },
        ],
        ['sectionId', 'questionId', 'submissionId'],
      ),
    ),
  );
};

const onUpdateSingleChoiceQuestionAnswers = (
  questionAnswers: (
    | SingleChoiceAnswer
    | EssayAnswer
    | MultipleChoiceAnswer
    | FillInBlankGroupAnswer
  )[],
  createSingleChoiceAnswerInput: CreateSingleChoiceAnswerInput,
) => {
  const newSingleChoiceAnswer = SingleChoiceAnswer.create({
    value: SingleMultipleChoiceAnswerValue.create({
      answerId: createSingleChoiceAnswerInput.value.answerId,
    }).getValue(),
    id: createSingleChoiceAnswerInput.id,
    sectionId: createSingleChoiceAnswerInput.sectionId,
    questionId: createSingleChoiceAnswerInput.questionId,
    type: AnswerType.SingleChoice,
  }).getValue();
  return [...questionAnswers, newSingleChoiceAnswer];
};

const onUpdateMultipleChoiceQuestionAnswers = (
  questionAnswers: (
    | SingleChoiceAnswer
    | EssayAnswer
    | MultipleChoiceAnswer
    | FillInBlankGroupAnswer
  )[],
  createMulTipleChoiceAnswerInput: CreateMultipleChoiceAnswerInput,
) => {
  const newSingleChoiceAnswer = MultipleChoiceAnswer.create({
    values: [
      SingleMultipleChoiceAnswerValue.create({
        answerId: createMulTipleChoiceAnswerInput.values[0].answerId,
      }).getValue(),
    ],
    id: createMulTipleChoiceAnswerInput.id,
    sectionId: createMulTipleChoiceAnswerInput.sectionId,
    questionId: createMulTipleChoiceAnswerInput.questionId,
    type: AnswerType.MultipleChoice,
  }).getValue();
  return [...questionAnswers, newSingleChoiceAnswer];
};

const onUpdateFillInBlankQuestionAnswers = (
  questionAnswers: (
    | SingleChoiceAnswer
    | EssayAnswer
    | MultipleChoiceAnswer
    | FillInBlankGroupAnswer
  )[],
  createFillInBlankAnswerInput: CreateFillInBlankAnswerInput,
) => {
  const questionAnswersUpdate = [...questionAnswers] as FillInBlankGroupAnswer[];
  const questionAnswerUpdateIndex = questionAnswersUpdate.findIndex(
    (a) =>
      a.questionId === createFillInBlankAnswerInput.questionId &&
      a.sectionId === createFillInBlankAnswerInput.sectionId,
  );
  if (questionAnswerUpdateIndex < 0) {
    const newFillInBlankGroupAnswer = FillInBlankGroupAnswer.create({
      sectionId: createFillInBlankAnswerInput.sectionId,
      questionId: createFillInBlankAnswerInput.questionId,
      type: AnswerType.FillInBlank,
      answers: [
        FillInBlankAnswer.create({
          answerBlankId: createFillInBlankAnswerInput.answerBlankId,
          value: FillInBlankAnswerValue.create({
            text: createFillInBlankAnswerInput.value.text,
          }).getValue(),
          id: createFillInBlankAnswerInput.id,
          sectionId: createFillInBlankAnswerInput.sectionId,
          questionId: createFillInBlankAnswerInput.questionId,
          type: AnswerType.FillInBlank,
        }).getValue(),
      ],
    }).getValue();
    questionAnswersUpdate.push(newFillInBlankGroupAnswer);
  } else {
    const newFillInBlankAnswer = FillInBlankAnswer.create({
      answerBlankId: createFillInBlankAnswerInput.answerBlankId,
      value: FillInBlankAnswerValue.create({
        text: createFillInBlankAnswerInput.value.text,
      }).getValue(),
      id: createFillInBlankAnswerInput.id,
      sectionId: createFillInBlankAnswerInput.sectionId,
      questionId: createFillInBlankAnswerInput.questionId,
      type: AnswerType.FillInBlank,
    }).getValue();
    questionAnswersUpdate[questionAnswerUpdateIndex].answers.push(newFillInBlankAnswer);
  }
  return questionAnswersUpdate;
};

const onUpdateEssayQuestionAnswers = (
  questionAnswers: (
    | SingleChoiceAnswer
    | EssayAnswer
    | MultipleChoiceAnswer
    | FillInBlankGroupAnswer
  )[],
  createEssayAnswerInput: CreateEssayAnswerInput,
) => {
  const newEssayAnswer = EssayAnswer.create({
    value: EssayAnswerValue.create(createEssayAnswerInput.value).getValue(),
    id: createEssayAnswerInput.id,
    sectionId: createEssayAnswerInput.sectionId,
    questionId: createEssayAnswerInput.questionId,
    type: AnswerType.Essay,
  }).getValue();
  return [...questionAnswers, newEssayAnswer];
};

export const handleGetAssignmentByIdUseCase = {
  executing: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      getAssignmentStatus: SubmissionPageStatus.EXECUTE,
      isLoadingGetAssignmentById: true,
    };
  },
  success: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    return {
      ...state,
      assignment: action.payload,
      getAssignmentStatus: SubmissionPageStatus.SUCCESS,
      isLoadingGetAssignmentById: false,
    };
  },
  error: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      getAssignmentStatus: SubmissionPageStatus.ERROR,
      isLoadingGetAssignmentById: false,
    };
  },
};

export const handleGetFromReleaseByIdUseCase = {
  executing: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      getFromReleaseStatus: SubmissionPageStatus.EXECUTE,
    };
  },
  success: (state: SubmissionPageState, action: Action) => {
    return {
      ...state,
      fromRelease: action.payload,
      getFromReleaseStatus: SubmissionPageStatus.SUCCESS,
    };
  },
  error: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      getFromReleaseStatus: SubmissionPageStatus.ERROR,
    };
  },
};

export const handleChangeSectionUseCase = {
  executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { payload } = action;

    return {
      ...state,
      updateSelectedSectionStatus: SubmissionPageStatus.EXECUTE,
      sectionSelectedIndex: payload.sectionSelectedIndex,
      sectionSelected: state.fromRelease.getSectionByIndex(payload.sectionSelectedIndex),
    };
  },
  success: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      updateSelectedSectionStatus: SubmissionPageStatus.SUCCESS,
    };
  },
};

export const handleFindQuestionReleasesUseCase = {
  executing: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      findQuestionReleaseStatus: SubmissionPageStatus.EXECUTE,
    };
  },
  success: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { payload } = action;
    return {
      ...state,
      questionReleases: payload,
      findQuestionReleaseStatus: SubmissionPageStatus.SUCCESS,
    };
  },
  error: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      findQuestionReleaseStatus: SubmissionPageStatus.ERROR,
    };
  },
};

export const handleGetResponderSubmissionUseCase = {
  executing: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      isLoadingSubmissions: true,
      getSubmissionStatus: SubmissionPageStatus.EXECUTE,
    };
  },
  success: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const submission = action.payload as SubmissionSummary;
    return {
      ...state,
      submission,
      isLoadingSubmissions: false,
      getSubmissionStatus: SubmissionPageStatus.SUCCESS,
    };
  },
  error: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      isLoadingSubmissions: false,
      getSubmissionStatus: SubmissionPageStatus.ERROR,
    };
  },
};

export const handleGetAnswersUseCase = {
  executing: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      getAnswersStatus: SubmissionPageStatus.EXECUTE,
    };
  },
  success: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    return {
      ...state,
      getAnswersStatus: SubmissionPageStatus.SUCCESS,
      questionAnswers: action.payload,
    };
  },
  error: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      getAnswersStatus: SubmissionPageStatus.ERROR,
    };
  },
};

export const handleCreateSingleChoiceAnswer = {
  executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const useCaseInput = action.payload as CreateAnswerUseCaseInput<CreateSingleChoiceAnswerInput>;
    const { input } = useCaseInput;
    const questionAnswered = [];

    onUpdateAnsweredQuestionCached(useCaseInput.submissionId, input.sectionId, input.questionId);
    questionAnswered.push({
      submissionId: useCaseInput.submissionId,
      sectionId: input.sectionId,
      questionId: input.questionId,
    });

    const questionAnswers = onUpdateSingleChoiceQuestionAnswers(state.questionAnswers, input);

    return {
      ...state,
      createAnswerStatus: SubmissionPageStatus.EXECUTE,
      questionAnswered,
      questionAnswers,
    };
  },
  success: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      createAnswerStatus: SubmissionPageStatus.SUCCESS,
    };
  },
  error: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      createAnswerStatus: SubmissionPageStatus.ERROR,
    };
  },
};

export const handleCreateMultipleChoiceAnswer = {
  executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const useCaseInput =
      action.payload as CreateAnswerUseCaseInput<CreateMultipleChoiceAnswerInput>;
    const { input } = useCaseInput;
    const questionAnswered = [];

    onUpdateAnsweredQuestionCached(useCaseInput.submissionId, input.sectionId, input.questionId);

    questionAnswered.push({
      submissionId: useCaseInput.submissionId,
      sectionId: input.sectionId,
      questionId: input.questionId,
    });

    const questionAnswers = onUpdateMultipleChoiceQuestionAnswers(state.questionAnswers, input);

    return {
      ...state,
      createAnswerStatus: SubmissionPageStatus.EXECUTE,
      questionAnswered,
      questionAnswers,
    };
  },
  success: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      createAnswerStatus: SubmissionPageStatus.SUCCESS,
    };
  },
  error: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      createAnswerStatus: SubmissionPageStatus.ERROR,
    };
  },
};

export const handleCreateFillInBlankAnswer = {
  executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const useCaseInput = action.payload as CreateAnswerUseCaseInput<CreateFillInBlankAnswerInput>;
    const { input } = useCaseInput;
    const questionAnswered = [];

    onUpdateAnsweredQuestionCached(useCaseInput.submissionId, input.sectionId, input.questionId);

    questionAnswered.push({
      submissionId: useCaseInput.submissionId,
      sectionId: input.sectionId,
      questionId: input.questionId,
    });

    const questionAnswers = onUpdateFillInBlankQuestionAnswers(state.questionAnswers, input);

    return {
      ...state,
      createAnswerStatus: SubmissionPageStatus.EXECUTE,
      questionAnswered,
      questionAnswers,
    };
  },
  success: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      createAnswerStatus: SubmissionPageStatus.SUCCESS,
    };
  },
  error: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      createAnswerStatus: SubmissionPageStatus.ERROR,
    };
  },
  reset: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      createAnswerStatus: SubmissionPageStatus.RESET,
    };
  },
};

export const handleCreateEssayAnswer = {
  executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const useCaseInput = action.payload as CreateAnswerUseCaseInput<CreateEssayAnswerInput>;
    const { input } = useCaseInput;
    const questionAnswered = [];

    onUpdateAnsweredQuestionCached(useCaseInput.submissionId, input.sectionId, input.questionId);

    questionAnswered.push({
      submissionId: useCaseInput.submissionId,
      sectionId: input.sectionId,
      questionId: input.questionId,
    });

    const questionAnswers = onUpdateEssayQuestionAnswers(state.questionAnswers, input);

    return {
      ...state,
      createAnswerStatus: SubmissionPageStatus.EXECUTE,
      questionAnswered,
      questionAnswers,
    };
  },
  success: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      createAnswerStatus: SubmissionPageStatus.SUCCESS,
    };
  },
  error: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      createAnswerStatus: SubmissionPageStatus.ERROR,
    };
  },
};

export const handlePausePracticeSubmissionUseCase = {
  executing: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      pausePracticeSubmissionStatus: SubmissionPageStatus.EXECUTE,
    };
  },
  success: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      pausePracticeSubmissionStatus: SubmissionPageStatus.SUCCESS,
      resumePracticeSubmissionStatus: SubmissionPageStatus.RESET,
      submitPracticeSubmissionStatus: SubmissionPageStatus.RESET,
    };
  },
  error: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      pausePracticeSubmissionStatus: SubmissionPageStatus.ERROR,
    };
  },
};

export const handleResumePracticeSubmissionUseCase = {
  executing: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      resumePracticeSubmissionStatus: SubmissionPageStatus.EXECUTE,
      isLoadingResumePracticeSubmission: true,
    };
  },
  success: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      resumePracticeSubmissionStatus: SubmissionPageStatus.SUCCESS,
      pausePracticeSubmissionStatus: SubmissionPageStatus.RESET,
      submitPracticeSubmissionStatus: SubmissionPageStatus.RESET,
      isLoadingResumePracticeSubmission: false,
    };
  },
  error: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    const { error } = action.payload;
    return {
      ...state,
      error,
      resumePracticeSubmissionStatus: SubmissionPageStatus.ERROR,
      isLoadingResumePracticeSubmission: false,
    };
  },
};

export const handleSubmitPracticeSubmissionUseCase = {
  executing: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      submitPracticeSubmissionStatus: SubmissionPageStatus.EXECUTE,
    };
  },
  success: (state: SubmissionPageState): SubmissionPageState => {
    const { submission } = state;
    submission.updateStatus(
      ConfigType.create({
        id: SubmissionStatus.SUBMITTED,
        name: 'Submitted',
      }).getValue(),
    );
    const newSubmission = SubmissionSummary.create(submission.toJsonObject()).getValue();
    return {
      ...state,
      submission: newSubmission,
      submitPracticeSubmissionStatus: SubmissionPageStatus.SUCCESS,
    };
  },
  error: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      submitPracticeSubmissionStatus: SubmissionPageStatus.ERROR,
    };
  },
};

export const handleSetSubmitPracticeSubmissionTime = {
  executing: (state: SubmissionPageState): SubmissionPageState => {
    return {
      ...state,
      setSubmitPracticeSubmissionTimeStatus: SubmissionPageStatus.SUCCESS,
    };
  },
};

export const handleSetStopCountDownPracticeSubmission = {
  executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    return {
      ...state,
      setStopCountDownPracticeSubmissionStatus: action.payload,
    };
  },
};

export const handleSetIsEndTimePracticeSubmission = {
  executing: (state: SubmissionPageState, action: Action): SubmissionPageState => {
    return {
      ...state,
      setIsEndTimePracticeSubmissionStatus: action.payload,
    };
  },
};
