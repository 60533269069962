import {
  SubmissionSummary,
  EssayAnswer,
  FillInBlankAnswer,
  MultipleChoiceAnswer,
  PracticeAssignment,
  SingleChoiceAnswer,
  FillInBlankGroupAnswer,
} from '@module/assignment';
import {
  EssayQuestion,
  FillInBlankQuestion,
  FormRelease,
  MultipleChoiceQuestion,
  SectionSummary,
  SingleChoiceQuestion,
} from '@module/form';
import { RootReducerState } from '../root.reducer';
import { SubmissionModalStatus, SubmissionPageStatus } from './submission.reducer';

export const getAssignmentSelector = (state: RootReducerState): PracticeAssignment => {
  return state.submissionPage.assignment;
};

export const loadingGetAssignmentByIdSelector = (state: RootReducerState): boolean => {
  return state.submissionPage.isLoadingGetAssignmentById;
};

export const getAssignmentStatusSelector = (state: RootReducerState): SubmissionPageStatus => {
  return state.submissionPage.getAssignmentStatus;
};

export const getFromReleaseSelector = (state: RootReducerState): FormRelease => {
  return state.submissionPage.fromRelease;
};

export const getFromReleaseStatusSelector = (state: RootReducerState): SubmissionPageStatus => {
  return state.submissionPage.getFromReleaseStatus;
};

export const getSectionSelectedSelector = (state: RootReducerState): SectionSummary => {
  return state.submissionPage.sectionSelected;
};

export const updateSectionSelectedStatusSelector = (
  state: RootReducerState,
): SubmissionPageStatus => {
  return state.submissionPage.updateSelectedSectionStatus;
};

export const getSectionSelectedIndexSelector = (state: RootReducerState): number => {
  return state.submissionPage.sectionSelectedIndex;
};

export const getQuestionReleasesStatusSelector = (
  state: RootReducerState,
): SubmissionPageStatus => {
  return state.submissionPage.findQuestionReleaseStatus;
};

export const getQuestionReleasesSelector = (
  state: RootReducerState,
): (EssayQuestion | SingleChoiceQuestion | MultipleChoiceQuestion | FillInBlankQuestion)[] => {
  return state.submissionPage.questionReleases;
};

// export const getSubmissionsSelector = (state: RootReducerState): SubmissionSummary[] => {
//   return state.submissionPage.submissions;
// };

export const getSubmissionSelector = (state: RootReducerState): SubmissionSummary => {
  return state.submissionPage.submission;
};

export const isLoadingSubmissionSelector = (state: RootReducerState): boolean => {
  return state.submissionPage.isLoadingSubmissions;
};

export const getSubmissionStatusSelector = (state: RootReducerState): number => {
  return state.submissionPage.getSubmissionStatus;
};

export const getAnswersStatusSelector = (state: RootReducerState): SubmissionPageStatus => {
  return state.submissionPage.getAnswersStatus;
};

export const questionAnswersSelector = (
  state: RootReducerState,
): (EssayAnswer | SingleChoiceAnswer | MultipleChoiceAnswer | FillInBlankGroupAnswer)[] => {
  return state.submissionPage.questionAnswers;
};

export const resumePracticeSubmissionStatusSelector = (state: RootReducerState): number => {
  return state.submissionPage.resumePracticeSubmissionStatus;
};

export const loadingResumePracticeSubmissionSelector = (state: RootReducerState): boolean => {
  return state.submissionPage.isLoadingResumePracticeSubmission;
};

export const createAnswerStatusSelector = (state: RootReducerState): SubmissionPageStatus => {
  return state.submissionPage.createAnswerStatus;
};

export const pausePracticeSubmissionStatusSelector = (
  state: RootReducerState,
): SubmissionPageStatus => {
  return state.submissionPage.pausePracticeSubmissionStatus;
};

export const submitPracticeSubmissionStatusSelector = (
  state: RootReducerState,
): SubmissionPageStatus => {
  return state.submissionPage.submitPracticeSubmissionStatus;
};

export const questionAnsweredSelector = (
  state: RootReducerState,
): {
  sectionId: string;
  questionId: string;
  submissionId: string;
}[] => {
  return state.submissionPage.questionAnswered;
};

export const setSubmitPracticeSubmissionTimeStatusSelector = (
  state: RootReducerState,
): SubmissionPageStatus => {
  return state.submissionPage.setSubmitPracticeSubmissionTimeStatus;
};

export const setStopCountDownPracticeSubmissionStatusSelector = (
  state: RootReducerState,
): boolean => {
  return state.submissionPage.setStopCountDownPracticeSubmissionStatus;
};

export const setIsEndTimePracticeSubmissionStatusSelector = (
  state: RootReducerState,
): SubmissionModalStatus => {
  return state.submissionPage.setIsEndTimePracticeSubmissionStatus;
};
