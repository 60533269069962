import { applyMiddleware, createStore, compose } from "redux";
import { createEpicMiddleware } from "redux-observable";
import { rootEpic } from "./root.epic";
import { rootReducer } from "./root.reducer";
import { Context, createWrapper } from "next-redux-wrapper";

const epicMiddleware = createEpicMiddleware();
const composeEnhancers = compose;

export const configureStore = () => {
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(epicMiddleware))
  );

  epicMiddleware.run(rootEpic);
  return store;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const makeStore = (context: Context) => configureStore();

export const wrapper = createWrapper(makeStore, { debug: true });
