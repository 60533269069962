import { CreateEssayAnswerUseCase, CreateFillInBlankAnswerUseCase, CreateMultipleChoiceAnswerUseCase, CreateSingleChoiceAnswerUseCase, UpdateEssayAnswerUseCase, UpdateSingleChoiceAnswerUseCase, UpdateMultipleChoiceAnswerUseCase, UpdateFillInBlankAnswerUseCase, GetAnswersUseCase } from './answer';
import { GetAssignmentsUseCase, GetAssignmentByIdUseCase, UpdateAssignmentUseCase, DeleteAssignmentUseCase, CreatePracticeAssignmentUseCase, GetParticipatedAssignmentUseCase } from './assignment';
import { CreateAttendeeUseCase, GetAttendeesUseCase } from './attendee';
import { GetAssignmentConfigurationsUseCase } from './get-assignment-configurations';
import { GetSubmissionsUseCase, GetSubmissionByIdUseCase, CreateSubmissionUseCase, UpdateSubmissionUseCase, GetSubmissionsResponderUseCase, SubmitSubmissionsUseCase } from './submission';
export * from './assignment';
export * from './attendee';
export * from './get-assignment-configurations';
export * from './submission';
export * from './answer';
export var assignmentUseCases = [CreatePracticeAssignmentUseCase, UpdateAssignmentUseCase, GetAssignmentsUseCase, GetAssignmentConfigurationsUseCase, GetAssignmentByIdUseCase, DeleteAssignmentUseCase, GetParticipatedAssignmentUseCase];
export var attendeeUseCases = [CreateAttendeeUseCase, GetAttendeesUseCase];
export var submissionUseCases = [GetSubmissionsUseCase, GetSubmissionByIdUseCase, CreateSubmissionUseCase, UpdateSubmissionUseCase, GetSubmissionsResponderUseCase, SubmitSubmissionsUseCase];
export var answerUseCases = [GetAnswersUseCase, // Create
CreateEssayAnswerUseCase, CreateSingleChoiceAnswerUseCase, CreateMultipleChoiceAnswerUseCase, CreateFillInBlankAnswerUseCase, // Update
UpdateEssayAnswerUseCase, UpdateSingleChoiceAnswerUseCase, UpdateMultipleChoiceAnswerUseCase, UpdateFillInBlankAnswerUseCase];
export var assignmentModuleUseCases = [].concat(assignmentUseCases, attendeeUseCases, submissionUseCases, answerUseCases);