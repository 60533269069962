import { Action } from "@lib/plugin-redux-core";
import { User } from "@module/user";
import { PageLayoutState, PageLayoutStatus } from "./layout.reducer";

export const handleGetUserByIdUseCase = {
  executing: (state: PageLayoutState): PageLayoutState => {
    return {
      ...state,
      getUserByIdStatus: PageLayoutStatus.EXECUTE,
    };
  },
  success: (state: PageLayoutState, action: Action): PageLayoutState => {
    const { payload } = action;

    return {
      ...state,
      userResponse: payload as User,
      getUserByIdStatus: PageLayoutStatus.SUCCESS,

    };
  },
  error: (state: PageLayoutState, action: Action): PageLayoutState => {
    const { error } = action;
    return {
      ...state,
      getUserByIdStatus: PageLayoutStatus.ERROR,
      error,
    };
  },
};

export const handleSignOutUseCase = {
  executing: (state: PageLayoutState): PageLayoutState => {
    return {
      ...state,
      signOutStatus: PageLayoutStatus.EXECUTE
    };
  },
  success: (state: PageLayoutState): PageLayoutState => {
    return {
      ...state,
      signOutStatus: PageLayoutStatus.SUCCESS,
    };
  },
  error: (state: PageLayoutState, action: Action): PageLayoutState => {
    const { error } = action;
    return {
      ...state,
      signOutStatus: PageLayoutStatus.ERROR,
      error,
    };
  },
};