import { authenticationUseCaseProviders } from './authentication.provider';
import { assignmentUseCaseProviders } from './assignment.provider';
import { attendeeUseCaseProviders } from './attendee.provider';
import { formReleaseUseCaseProviders } from './form-release.provider';
import { questionReleasesUseCaseProviders } from './question-release.provider';
import { submissionUseCaseProviders } from './submission.provider';
import { participatedAssignmentUseCaseProviders } from './participated-assignment.provider';
import { subjectUseCaseProviders } from './subject.provider';
import { userUseCaseProviders } from './user.provider';

export const useCaseProviders = [
  ...attendeeUseCaseProviders,
  ...authenticationUseCaseProviders,
  ...assignmentUseCaseProviders,
  ...formReleaseUseCaseProviders,
  ...questionReleasesUseCaseProviders,
  ...submissionUseCaseProviders,
  ...participatedAssignmentUseCaseProviders,
  ...subjectUseCaseProviders,
  ...userUseCaseProviders,
];
