import { PracticeAssignment, SubmissionSummary } from '@module/assignment';
import { RootReducerState } from 'redux/root.reducer';
import { AssignmentDetailPageStatus } from './assignment-detail.reducer';

export const getAssignmentSelector = (
  state: RootReducerState,
): PracticeAssignment => {
  return state.assignmentDetailPage.assignment;
};

export const getAssignmentStatusSelector = (
  state: RootReducerState,
): AssignmentDetailPageStatus => {
  return state.assignmentDetailPage.getAssignmentStatus;
};

export const getSubmissionsSelector = (
  state: RootReducerState,
): SubmissionSummary[] => {
  return state.assignmentDetailPage.submissions;
};

export const getSubmissionsStatusSelector = (
  state: RootReducerState,
): AssignmentDetailPageStatus => {
  return state.assignmentDetailPage.getSubmissionsStatus;
};

export const createAttendeeStatusSelector = (
  state: RootReducerState,
): AssignmentDetailPageStatus => {
  return state.assignmentDetailPage.createAttendeeStatus;
};

export const getAttendeeIdSelector = (state: RootReducerState): string => {
  return state.assignmentDetailPage.attendeeId;
};

export const createSubmissionStatusSelector = (
  state: RootReducerState,
): AssignmentDetailPageStatus => {
  return state.assignmentDetailPage.createSubmissionStatus;
};

export const isLoadingCreateSubmissionSelector = (
  state: RootReducerState,
): boolean => {
  return state.assignmentDetailPage.isLoadingCreateSubmission;
};

export const newSubmissionIdSelector = (state: RootReducerState): string => {
  return state.assignmentDetailPage.newSubmissionId;
};
