export const SIGN_OUT_USE_CASE = 'SIGN_OUT_USE_CASE';
export const GET_ACCESS_TOKEN_USE_CASE = 'GET_ACCESS_TOKEN_USE_CASE';

export const GET_ASSIGNMENT_DETAIL_USE_CASE = 'GET_ASSIGNMENT_DETAIL_USE_CASE';
export const CREATE_ATTENDEE_USE_CASE = 'CREATE_ATTENDEE_USE_CASE';

export const FIND_FORM_RELEASE_USE_CASE = 'FIND_FORM_RELEASE_USE_CASE';

export const CHANGE_SECTION_SELECTED_USE_CASE = 'CHANGE_SECTION_SELECTED_USE_CASE';

export const FIND_QUESTION_RELEASES_USE_CASE = 'FIND_QUESTION_RELEASES_USE_CASE';

// Submission.
export const GET_SUBMISSION_BY_ID_USE_CASE = 'GET_SUBMISSION_BY_ID_USE_CASE';
export const CREATE_SUBMISSION_USE_CASE = 'CREATE_SUBMISSION_USE_CASE';
export const UPDATE_SUBMISSION_USE_CASE = 'UPDATE_SUBMISSION_USE_CASE';
export const GET_SELECTED_QUESTION_INDEX = 'GET_SELECTED_QUESTION_INDEX';

// Answer
export const GET_ANSWERS_USE_CASE = 'GET_ANSWERS_USE_CASE';

export const CREATE_ESSAY_ANSWER_USE_CASE = 'CREATE_ESSAY_ANSWER_USE_CASE';
export const CREATE_SINGLE_CHOICE_ANSWER_USE_CASE = 'CREATE_SINGLE_CHOICE_ANSWER_USE_CASE';
export const CREATE_MULTIPLE_CHOICE_ANSWER_USE_CASE = 'CREATE_MULTIPLE_CHOICE_ANSWER_USE_CASE';
export const CREATE_FILL_IN_BLANK_ANSWER_USE_CASE = 'CREATE_FILL_IN_BLANK_ANSWER_USE_CASE';

export const UPDATE_ESSAY_ANSWER_USE_CASE = 'UPDATE_ESSAY_ANSWER_USE_CASE';
export const UPDATE_SINGLE_CHOICE_ANSWER_USE_CASE = 'UPDATE_SINGLE_CHOICE_ANSWER_USE_CASE';
export const UPDATE_MULTIPLE_CHOICE_ANSWER_USE_CASE = 'UPDATE_MULTIPLE_CHOICE_ANSWER_USE_CASE';
export const UPDATE_FILL_IN_BLANK_ANSWER_USE_CASE = 'UPDATE_FILL_IN_BLANK_ANSWER_USE_CASE';

export const UPDATE_SUBMISSION_END_TIME = 'UPDATE_SUBMISSION_END_TIME';

export const SIDEBAR_USE_CASE = 'SIDEBAR_USE_CASE';
export const GET_USERS_USE_CASE = 'GET_USERS_USE_CASE';
export const SAVE_RESUME_TIME = 'SAVE_RESUME_TIME';

export const SET_SUBMIT_PRACTICE_SUBMISSION_TIME = 'SET_SUBMIT_PRACTICE_SUBMISSION_TIME';
export const SET_STOP_COUNT_DOWN_PRACTICE_SUBMISSION = 'SET_STOP_COUNT_DOWN_PRACTICE_SUBMISSION';
export const SET_IS_END_TIME_PRACTICE_SUBMISSION = 'SET_IS_END_TIME_PRACTICE_SUBMISSION';
