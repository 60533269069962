export var AssignmentStatus;

(function (AssignmentStatus) {
  AssignmentStatus["PROCESSING"] = "1";
  AssignmentStatus["CANCELED"] = "2";
  AssignmentStatus["ACTIVE"] = "3";
  AssignmentStatus["UPCOMING"] = "5";
  AssignmentStatus["ONGOING"] = "6";
  AssignmentStatus["ENDED"] = "7";
  AssignmentStatus["PASS"] = "10";
  AssignmentStatus["FAIL"] = "11";
})(AssignmentStatus || (AssignmentStatus = {}));