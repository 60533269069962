import { styleVariables } from "../../../style";
export var COLOR_TYPE = {
  DEFAULT: "default",
  SECONDARY: "secondary"
};
export var FONT_WEIGHT_TYPE = {
  NORMAL: "normal",
  STRONG: "strong"
};
export var FONT_SIZE_TYPE = {
  SMALL: "small",
  ORIGINAL: "original",
  MEDIUM: "medium",
  LARGE: "large",
  SUPPORT: "support"
};
export var handleColorType = function handleColorType(color) {
  switch (color) {
    case COLOR_TYPE.SECONDARY:
      return styleVariables.colors.gray10;

    default:
      return styleVariables.colors.gray12;
  }
};
export var handleFontWeight = function handleFontWeight(fontWeight) {
  switch (fontWeight) {
    case FONT_WEIGHT_TYPE.STRONG:
      return styleVariables.font.fontWeightStrong;

    default:
      return styleVariables.font.fontWeightNormal;
  }
};
export var handleFontSize = function handleFontSize(color) {
  switch (color) {
    case FONT_SIZE_TYPE.SMALL:
      return styleVariables.font.fontSizeSmall;

    case FONT_SIZE_TYPE.MEDIUM:
      return styleVariables.font.fontSizeMedium;

    case FONT_SIZE_TYPE.LARGE:
      return styleVariables.font.fontSizeLarge;

    case FONT_SIZE_TYPE.SUPPORT:
      return styleVariables.font.fontSizeSupport;

    default:
      return styleVariables.font.fontSizeOriginal;
  }
};