import { Action, createReducer } from '@lib/plugin-redux-core';
import {
  GET_PARTICIPATED_ASSIGNMENT_USE_CASE,
  ParticipatedAssignmentResponder,
} from '@module/assignment';
import { GET_SUBJECTS_USE_CASE, Subject } from '@module/form';
import { UseCaseError } from '@smart-form/domains';

export type GetParticipatedAssignmentResponderUseCaseState = {
  participatedAssignmentResponse: ParticipatedAssignmentResponder[],
  isParticipatedAssignmentLoading: boolean,
};
export type GetSubjectsUseCaseState = {
  subjectResponses: Subject[];
};
export type ErrorState = {
  error?: UseCaseError<any>;
}
export type ParticipatedAssignmentState = GetParticipatedAssignmentResponderUseCaseState & ErrorState
  & GetSubjectsUseCaseState;

export const initialState: ErrorState = {
  error: null,
};

export const participatedAssignmentReducer = createReducer(initialState, [
  {
    name: GET_PARTICIPATED_ASSIGNMENT_USE_CASE,
    executing: (state: ParticipatedAssignmentState): ParticipatedAssignmentState => {
      return {
        ...state,
        isParticipatedAssignmentLoading: true,
      };
    },
    success: (state: ParticipatedAssignmentState, action: Action): ParticipatedAssignmentState => {
      const { payload } = action;
      
      return {
        ...state,
        participatedAssignmentResponse: payload as ParticipatedAssignmentResponder[],
        isParticipatedAssignmentLoading: false,
      };
    },
    error: (state: ParticipatedAssignmentState, action: Action): ParticipatedAssignmentState => {
      const { error } = action;

      return {
        ...state,
        error,
        isParticipatedAssignmentLoading: false,
      };
    },
  },
  {
    name: GET_SUBJECTS_USE_CASE,
    executing: (state: ParticipatedAssignmentState): ParticipatedAssignmentState => {
      return {
        ...state,
      };
    },
    success: (state: ParticipatedAssignmentState, action: Action): ParticipatedAssignmentState => {
      const { payload } = action;
      
      return {
        ...state,
        subjectResponses: payload as Subject[],
      };
    },
    error: (state: ParticipatedAssignmentState, action: Action): ParticipatedAssignmentState => {
      const { error } = action;

      return {
        ...state,
        error,
      };
    },
  }
]);
