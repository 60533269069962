import { createReducer } from '@lib/plugin-redux-core';
import {
  CREATE_ESSAY_ANSWER_USE_CASE,
  PAUSE_PRACTICE_SUBMISSION_USE_CASE,
  PracticeAssignment,
  RESUME_PRACTICE_SUBMISSION_USE_CASE,
  SingleChoiceAnswer,
  CREATE_FILL_IN_BLANK_ANSWER_USE_CASE,
  CREATE_MULTIPLE_CHOICE_ANSWER_USE_CASE,
  CREATE_SINGLE_CHOICE_ANSWER_USE_CASE,
  EssayAnswer,
  GET_ANSWERS_USE_CASE,
  GET_ASSIGNMENT_BY_ID_USE_CASE,
  GET_SUBMISSION_BY_ID_USE_CASE,
  MultipleChoiceAnswer,
  SubmissionSummary,
  SUBMIT_PRACTICE_SUBMISSION_USE_CASE,
  CreateAnswerUseCaseInput,
  CreateFillInBlankAnswerInput,
  FillInBlankGroupAnswer,
  CreateSingleChoiceAnswerInput,
  CreateMultipleChoiceAnswerInput,
  CreateEssayAnswerInput,
} from '@module/assignment';
import {
  EssayQuestion,
  FillInBlankQuestion,
  FIND_FORM_RELEASE_BY_ID_USE_CASE,
  FIND_QUESTION_RELEASES_USE_CASE,
  FormRelease,
  MultipleChoiceQuestion,
  SectionSummary,
  SingleChoiceQuestion,
} from '@module/form';
import { UseCaseError } from '@smart-form/domains';
import {
  CHANGE_SECTION_SELECTED_USE_CASE,
  SET_IS_END_TIME_PRACTICE_SUBMISSION,
  SET_STOP_COUNT_DOWN_PRACTICE_SUBMISSION,
  SET_SUBMIT_PRACTICE_SUBMISSION_TIME,
} from 'redux/root.action';
import {
  handleChangeSectionUseCase,
  handleCreateEssayAnswer,
  handleCreateFillInBlankAnswer,
  handleCreateMultipleChoiceAnswer,
  handleCreateSingleChoiceAnswer,
  handleFindQuestionReleasesUseCase,
  handleGetAnswersUseCase,
  handleGetAssignmentByIdUseCase,
  handleGetFromReleaseByIdUseCase,
  // handleGetSubmissionByIdUseCase,
  handlePausePracticeSubmissionUseCase,
  handleResumePracticeSubmissionUseCase,
  handleGetResponderSubmissionUseCase,
  handleSubmitPracticeSubmissionUseCase,
  handleSetSubmitPracticeSubmissionTime,
  handleSetStopCountDownPracticeSubmission,
  handleSetIsEndTimePracticeSubmission,
} from './submission.handler';

export const enum SubmissionPageStatus {
  EXECUTE,
  SUCCESS,
  ERROR,
  RESET,
}

export const enum SubmissionModalStatus {
  ACTIVE = 'ACTIVE',
  DISABLE = 'DISABLE',
}

export type SubmissionPageInitialState = {
  error?: UseCaseError<any>;
  questionAnswered: {
    sectionId: string;
    questionId: string;
    submissionId: string;
  }[];
};

type GetAssignmentByIdUseCaseState = {
  getAssignmentStatus: SubmissionPageStatus;
  assignment: PracticeAssignment;
  isLoadingGetAssignmentById: boolean;
};

type GetFromReleaseByIdUseCaseState = {
  getFromReleaseStatus: SubmissionPageStatus;
  fromRelease: FormRelease;
};

type GetAnswersUseCaseState = {
  getAnswersStatus: SubmissionPageStatus;
  questionAnswers: (
    | EssayAnswer
    | SingleChoiceAnswer
    | MultipleChoiceAnswer
    | FillInBlankGroupAnswer
  )[];
};

type UpdateSectionSelectedUseCaseState = {
  sectionSelectedIndex: number;
  sectionSelected: SectionSummary;
  updateSelectedSectionStatus: SubmissionPageStatus;
};

type GetQuestionReleasesUseCaseState = {
  findQuestionReleaseStatus: SubmissionPageStatus;
  questionReleases: (
    | EssayQuestion
    | SingleChoiceQuestion
    | MultipleChoiceQuestion
    | FillInBlankQuestion
  )[];
};

type CreateAnswerUseCaseState = {
  createAnswerStatus: SubmissionPageStatus;
};

type GetResponderSubmissionUseCaseState = {
  getSubmissionStatus: SubmissionPageStatus;
  submission: SubmissionSummary;
  isLoadingSubmissions: boolean;
};

type SubmitPracticeSubmissionUseCaseState = {
  submitPracticeSubmissionStatus: SubmissionPageStatus;
};

type PausePracticeSubmissionUseCaseState = {
  pausePracticeSubmissionStatus: SubmissionPageStatus;
};

type ResumePracticeSubmissionUseCaseState = {
  resumePracticeSubmissionStatus: SubmissionPageStatus;
  isLoadingResumePracticeSubmission: boolean;
};

type SetSubmitPracticeSubmissionTimeState = {
  setSubmitPracticeSubmissionTimeStatus: SubmissionPageStatus;
};

type SetStopCountDownPracticeSubmissionState = {
  setStopCountDownPracticeSubmissionStatus: boolean;
};

type SetIsEndTimePracticeSubmissionState = {
  setIsEndTimePracticeSubmissionStatus: SubmissionModalStatus;
};

type CreateSingleChoiceAnswerState = {
  createSingleChoiceAnswerInput: CreateAnswerUseCaseInput<CreateSingleChoiceAnswerInput>;
};

type CreateMultipleChoiceAnswerState = {
  createMultipleChoiceAnswerInput: CreateAnswerUseCaseInput<CreateMultipleChoiceAnswerInput>;
};

type CreateFillInBlankAnswerState = {
  createFillInBlankAnswerInput: CreateAnswerUseCaseInput<CreateFillInBlankAnswerInput>;
};

type CreateEssayAnswerState = {
  createEssayAnswerInput: CreateAnswerUseCaseInput<CreateEssayAnswerInput>;
};

export type SubmissionPageState = SubmissionPageInitialState &
  GetAssignmentByIdUseCaseState &
  GetFromReleaseByIdUseCaseState &
  GetQuestionReleasesUseCaseState &
  GetResponderSubmissionUseCaseState &
  GetAnswersUseCaseState &
  UpdateSectionSelectedUseCaseState &
  PausePracticeSubmissionUseCaseState &
  ResumePracticeSubmissionUseCaseState &
  CreateAnswerUseCaseState &
  SubmitPracticeSubmissionUseCaseState &
  SetSubmitPracticeSubmissionTimeState &
  SetStopCountDownPracticeSubmissionState &
  SetIsEndTimePracticeSubmissionState &
  CreateSingleChoiceAnswerState &
  CreateMultipleChoiceAnswerState &
  CreateFillInBlankAnswerState &
  CreateEssayAnswerState;

export const initialState: SubmissionPageInitialState = {
  error: null,
  questionAnswered: [],
};

export const submissionReducer = createReducer(initialState, [
  {
    name: GET_ASSIGNMENT_BY_ID_USE_CASE,
    ...handleGetAssignmentByIdUseCase,
  },
  {
    name: FIND_FORM_RELEASE_BY_ID_USE_CASE,
    ...handleGetFromReleaseByIdUseCase,
  },
  {
    name: CHANGE_SECTION_SELECTED_USE_CASE,
    ...handleChangeSectionUseCase,
  },
  {
    name: FIND_QUESTION_RELEASES_USE_CASE,
    ...handleFindQuestionReleasesUseCase,
  },
  {
    name: GET_SUBMISSION_BY_ID_USE_CASE,
    ...handleGetResponderSubmissionUseCase,
  },
  {
    name: PAUSE_PRACTICE_SUBMISSION_USE_CASE,
    ...handlePausePracticeSubmissionUseCase,
  },
  {
    name: RESUME_PRACTICE_SUBMISSION_USE_CASE,
    ...handleResumePracticeSubmissionUseCase,
  },
  {
    name: GET_ANSWERS_USE_CASE,
    ...handleGetAnswersUseCase,
  },
  {
    name: CREATE_SINGLE_CHOICE_ANSWER_USE_CASE,
    ...handleCreateSingleChoiceAnswer,
  },
  {
    name: CREATE_MULTIPLE_CHOICE_ANSWER_USE_CASE,
    ...handleCreateMultipleChoiceAnswer,
  },
  {
    name: CREATE_FILL_IN_BLANK_ANSWER_USE_CASE,
    ...handleCreateFillInBlankAnswer,
  },
  {
    name: CREATE_ESSAY_ANSWER_USE_CASE,
    ...handleCreateEssayAnswer,
  },
  {
    name: SUBMIT_PRACTICE_SUBMISSION_USE_CASE,
    ...handleSubmitPracticeSubmissionUseCase,
  },
  {
    name: SET_SUBMIT_PRACTICE_SUBMISSION_TIME,
    ...handleSetSubmitPracticeSubmissionTime,
  },
  {
    name: SET_STOP_COUNT_DOWN_PRACTICE_SUBMISSION,
    ...handleSetStopCountDownPracticeSubmission,
  },
  {
    name: SET_IS_END_TIME_PRACTICE_SUBMISSION,
    ...handleSetIsEndTimePracticeSubmission,
  },
]);
