import {
  GetUserByIdUseCase,
  GET_USER_BY_ID_USE_CASE,
  IUserRepository,
  USER_REPOSITORY,
} from "@module/user";

export const userUseCaseProviders: any = [
  {
    provide: GET_USER_BY_ID_USE_CASE,
    useFactory: (userRepository: IUserRepository) => {
      return new GetUserByIdUseCase(userRepository);
    },
    inject: [USER_REPOSITORY],
  },
];
