import { createReducer } from '@lib/plugin-redux-core';
import {
  CREATE_ATTENDEE_USE_CASE,
  CREATE_SUBMISSION_USE_CASE,
  GET_RESPONDER_ASSIGNMENT_USE_CASE,
  GET_SUBMISSIONS_FOR_RESPONDER_USE_CASE,
  PracticeAssignment,
  SubmissionSummary,
} from '@module/assignment';
import { UseCaseError } from '@smart-form/domains';
import {
  handleCreateAttendeeUseCase,
  handleCreateSubmissionUseCase,
  handleGetResponderAssignmentUseCase,
  handleGetSubmissionForResponderUseCase,
} from './assignment-detail.handler';

export const enum AssignmentDetailPageStatus {
  'EXECUTE' = 1,
  'SUCCESS' = 2,
  'ERROR' = 3,
  'RESET' = 4,
}

export type AssignmentDetailPageInitialState = {
  error?: UseCaseError<any>;
};
type GetAssignmentByIdUseCaseState = {
  getAssignmentStatus: AssignmentDetailPageStatus;
  assignment: PracticeAssignment;
};

type GetSubmissionsUseCaseState = {
  getSubmissionsStatus: AssignmentDetailPageStatus;
  submissions: SubmissionSummary[];
};

type CreateAttendeeUseCaseState = {
  createAttendeeStatus: AssignmentDetailPageStatus;
  attendeeId: string;
};

type CreateSubmissionUseCaseState = {
  createSubmissionStatus: AssignmentDetailPageStatus;
  newSubmissionId: string;
  isLoadingCreateSubmission: boolean;
};

export type AssignmentDetailPageState = AssignmentDetailPageInitialState &
  GetAssignmentByIdUseCaseState &
  GetSubmissionsUseCaseState &
  CreateAttendeeUseCaseState &
  CreateSubmissionUseCaseState;

export const initialState: AssignmentDetailPageInitialState = {
  error: null,
};

export const assignmentDetailReducer = createReducer(initialState, [
  {
    name: GET_RESPONDER_ASSIGNMENT_USE_CASE,
    ...handleGetResponderAssignmentUseCase,
  },
  {
    name: GET_SUBMISSIONS_FOR_RESPONDER_USE_CASE,
    ...handleGetSubmissionForResponderUseCase,
  },
  {
    name: CREATE_ATTENDEE_USE_CASE,
    ...handleCreateAttendeeUseCase,
  },
  {
    name: CREATE_SUBMISSION_USE_CASE,
    ...handleCreateSubmissionUseCase,
  },
]);
