import _defineProperty from "/vercel/path0/node_modules/.pnpm/@babel+runtime@7.15.4/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { error, reset, success } from "./action";
var defaultState = {};
export var createReducer = function createReducer() {
  var initialState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
  var useCases = arguments.length > 1 ? arguments[1] : undefined;
  var mapHandler = new Map();
  useCases.forEach(function (u) {
    var executingFunc = u && u.executing ? function () {
      var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
      var action = arguments.length > 1 ? arguments[1] : undefined;
      return u.executing(_objectSpread(_objectSpread({}, state), {}, {
        error: null
      }), action);
    } : function () {
      var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
      var action = arguments.length > 1 ? arguments[1] : undefined;
      return _objectSpread(_objectSpread({}, state), {}, {
        error: null
      });
    };
    var successFunc = u && u.success ? function () {
      var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
      var action = arguments.length > 1 ? arguments[1] : undefined;
      return u.success(_objectSpread(_objectSpread({}, state), {}, {
        error: null
      }), action);
    } : function () {
      var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
      var action = arguments.length > 1 ? arguments[1] : undefined;
      return _objectSpread(_objectSpread({}, state), {}, {
        error: null
      });
    };
    var errorFunc = u && u.error ? function () {
      var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
      var action = arguments.length > 1 ? arguments[1] : undefined;
      return u.error(_objectSpread(_objectSpread({}, state), {}, {
        error: action.error
      }), action);
    } : function () {
      var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
      var action = arguments.length > 1 ? arguments[1] : undefined;
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.payload.error
      });
    };
    var resetFunc = u && u.reset ? function () {
      var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
      var action = arguments.length > 1 ? arguments[1] : undefined;
      return u.reset(_objectSpread(_objectSpread({}, state), {}, {
        error: null
      }), action);
    } : function () {
      var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
      var action = arguments.length > 1 ? arguments[1] : undefined;
      return _objectSpread(_objectSpread({}, state), {}, {
        error: null
      });
    };
    mapHandler.set(u.name, executingFunc);
    mapHandler.set("".concat(u.name, "_").concat(success), successFunc);
    mapHandler.set("".concat(u.name, "_").concat(error), errorFunc);
    mapHandler.set("".concat(u.name, "_").concat(reset), resetFunc);
  });

  var reducer = function reducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    var type = action.type;

    if (!mapHandler.has(type)) {
      return state;
    }

    var handlerState = mapHandler.get(type);
    var newState = handlerState(state, action);
    return newState;
  };

  return reducer;
};