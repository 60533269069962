import {
  GetSubjectsUseCase,
  ISubjectRepository,
  SUBJECT_REPOSITORY,
  GET_SUBJECTS_USE_CASE,
} from '@module/form';

export const subjectUseCaseProviders = [
  {
    provide: GET_SUBJECTS_USE_CASE,
    useFactory: (subjectRepository: ISubjectRepository) => {
      return new GetSubjectsUseCase(subjectRepository);
    },
    inject: [SUBJECT_REPOSITORY],
  },
];
