var timerId;
export var useDebounce = function useDebounce() {
  var delay = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 300;

  var handleSetDebounce = function handleSetDebounce(callback) {
    clearTimeout(timerId);
    timerId = setTimeout(function () {
      callback();
    }, delay);
  };

  return handleSetDebounce;
};