export var breakpoints = {
  mobile: 576,
  tablet: 768,
  desktop: 992,
  widescreen: 10000
};
export var styleVariables = {
  colors: {
    gray1: '#ffffff',
    gray2: '#fafafa',
    gray3: '#f2f2f2',
    gray4: '#f0f0f0',
    gray5: '#e9e9e9',
    gray6: '#e3e4e5',
    gray7: '#e3e4e6',
    gray8: '#dadce0',
    gray9: '#d6d6d6',
    gray10: '#9A9A9A',
    gray11: '#616161',
    gray12: '#333333',
    gray13: '#000000',
    transparentGray6: 'rgba(0, 0, 0, 0.12)',
    lightBlue: '#F8F9FC',
    blue1: '#e6f2ff',
    blue2: '#1890ff',
    blue3: '#1a5aff',
    blue4: '#1616b9',
    cyan1: '#1a5a77',
    gold1: '#fa8c15',
    red1: '#ffccc7',
    red2: '#ff4d4f',
    red3: '#f5222d',
    red4: '#CF1322',
    red5: '#FFF1F0',
    green1: '#3a8814',
    green2: '#E8FCD5'
  },
  images: {
    brand: ''
  },
  font: {
    fontSFProDisplay: 'SF Pro Display',
    fontSizeSmall: '14px',
    fontSizeOriginal: '16px',
    fontSizeMedium: '20px',
    fontSizeLarge: '28px',
    fontSizeSupport: '12px',
    fontWeightNormal: '400',
    fontWeightStrong: '600'
  },
  responsive: {
    mobile: 640,
    tablet: 800,
    largeScreen: 1200
  },
  logo: {
    primary: {
      path: ''
    },
    white: {
      path: ''
    }
  },
  theme: 'snow',
  modules: {
    toolbar: [['bold', 'italic', 'underline']],
    clipboard: {
      matchVisual: false
    }
  },
  formats: ['bold', 'italic', 'underline', 'variable']
};