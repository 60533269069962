import {
  BadInputError,
  BodyResponse,
  NotFoundError,
  Result,
  UnAuthenticationError,
  UnExpectedError,
} from '@smart-form/domains';
import { HttpStatus, InternalServerErrorException } from '@nestjs/common';
import { AxiosError } from '@smart-form/plugin-axios';

export const ErrorResultHandler = <T>(error: any): Result<T> => {
  const axiosError = error as AxiosError;

  if (axiosError && axiosError.response) {
    const parsedError = axiosError.response.data as BodyResponse<void>;

    switch (parsedError.statusCode) {
      case HttpStatus.BAD_REQUEST: {
        return new BadInputError(parsedError.message);
      }
      case HttpStatus.UNAUTHORIZED:
        return new UnAuthenticationError(parsedError.message);

      case HttpStatus.NOT_FOUND:
        return new NotFoundError(parsedError.message);

      case HttpStatus.INTERNAL_SERVER_ERROR:
        return Result.fail(new InternalServerErrorException(parsedError));

      case HttpStatus.REQUEST_TIMEOUT:
        return new UnExpectedError(parsedError.message);

      case HttpStatus.NOT_IMPLEMENTED:
        return new UnExpectedError(parsedError.message);

      case HttpStatus.FORBIDDEN:
        return new UnExpectedError(parsedError.message);

      default:
        return Result.fail(error as Error);
    }
  }

  return Result.fail(error as Error);
};
