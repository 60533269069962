import { authenticationRepositoryProvider, userWebRepositoryProvider } from '@module/user';
import { Module } from '@nestjs/common';
import { useCaseProviders } from './usecase-providers';
import {
  assignmentRepositoryProvider,
  participatedAssignmentRepositoryProvider,
  submissionRepositoryProvider,
} from '@module/assignment';
import { apiProviders } from './api-providers';
import {
  formReleaseWebRepositoryProvider,
  questionReleasesWebRepositoryProvider,
  subjectRepositoryProvider,
} from '@module/form';
import { cachedProviders } from './cached-providers';
@Module({
  providers: [
    ...cachedProviders,
    ...apiProviders,
    authenticationRepositoryProvider,
    assignmentRepositoryProvider,
    userWebRepositoryProvider,
    formReleaseWebRepositoryProvider,
    questionReleasesWebRepositoryProvider,
    submissionRepositoryProvider,
    participatedAssignmentRepositoryProvider,
    subjectRepositoryProvider,
    ...useCaseProviders,
  ],
  exports: [...useCaseProviders],
})
export class AppModule {}
