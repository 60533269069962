import { useState, useEffect } from 'react';
import { css } from 'styled-components';
export var useResponsive = function useResponsive(_ref) {
  var mobile = _ref.mobile,
      tablet = _ref.tablet,
      desktop = _ref.desktop,
      widescreen = _ref.widescreen;

  var _useState = useState({
    width: undefined,
    height: undefined
  }),
      _ = _useState[0],
      setWindowSize = _useState[1];

  var minWidths = {
    mobile: 0,
    tablet: mobile + 1,
    desktop: tablet + 1,
    widescreen: desktop + 1
  };
  var maxWidths = {
    mobile: mobile,
    tablet: tablet,
    desktop: desktop,
    widescreen: widescreen
  };
  useEffect(function () {
    var handleResize = function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return function () {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  var getMediaQuery = function getMediaQuery(minWidth, maxWidth) {
    return function (args) {
      return css(["@media (min-width:", "px) and (max-width:", "px){", ";}"], minWidth, maxWidth, css(args));
    };
  };

  var media = {
    mobile: getMediaQuery(minWidths.mobile, maxWidths.mobile),
    tablet: getMediaQuery(minWidths.tablet, maxWidths.tablet),
    desktop: getMediaQuery(minWidths.desktop, maxWidths.desktop),
    widescreen: getMediaQuery(minWidths.widescreen, maxWidths.widescreen)
  };
  return media;
};