import {
  ASSIGNMENT_REPOSITORY,
  IAssignmentRepository,
  GET_ASSIGNMENT_BY_ID_USE_CASE,
  GetAssignmentByIdUseCase,
  GET_RESPONDER_ASSIGNMENT_USE_CASE,
  GetResponderAssignmentUseCase,
} from '@module/assignment';
import { IUserRepository } from '@module/user';
import { USER_REPOSITORY } from '@module/user/src/core/domain/repositories/user.repository';

export const assignmentUseCaseProviders = [
  {
    provide: GET_ASSIGNMENT_BY_ID_USE_CASE,
    useFactory: (
      assignmentRepository: IAssignmentRepository,
    ) => {
      return new GetAssignmentByIdUseCase(assignmentRepository);
    },
    inject: [ASSIGNMENT_REPOSITORY],
  },
  {
    provide: GET_RESPONDER_ASSIGNMENT_USE_CASE,
    useFactory: (
      assignmentRepository: IAssignmentRepository,
      userRepository: IUserRepository,
    ) => {
      return new GetResponderAssignmentUseCase(assignmentRepository, userRepository);
    },
    inject: [ASSIGNMENT_REPOSITORY, USER_REPOSITORY],
  },
];
