import {
  IAssignmentRepository,
  CreateAttendeeUseCase,
  ASSIGNMENT_REPOSITORY,
  CREATE_ATTENDEE_USE_CASE,
  GetAttendeeByIdUseCase,
  GET_ATTENDEE_BY_ID,
} from '@module/assignment';

export const attendeeUseCaseProviders = [
  {
    provide: CREATE_ATTENDEE_USE_CASE,
    useFactory: (assignmentRepository: IAssignmentRepository) => {
      return new CreateAttendeeUseCase(assignmentRepository);
    },
    inject: [ASSIGNMENT_REPOSITORY],
  },
  {
    provide: GET_ATTENDEE_BY_ID,
    useFactory: (assignmentRepository: IAssignmentRepository) => {
      return new GetAttendeeByIdUseCase(assignmentRepository);
    },
    inject: [ASSIGNMENT_REPOSITORY],
  },
];
