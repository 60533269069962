import { CreateFormUseCase, DeleteFormUseCase, FindFormByIdUseCase, UpdateFormUseCase } from './form';
import { CreateFolderUseCase, DeleteFolderUseCase, UpdateFolderUseCase } from './folder';
import { CreateSectionUseCase, UpdateSectionUseCase, DeleteSectionUseCase } from './section';
import { UpdateQuestionUseCase, DeleteQuestionUseCase } from './question';
import { CreateFillInBlankAnswerUseCase, DeleteFillInBlankAnswerUseCase, DeleteSingleMultipleChoiceAnswerUseCase, UpdateFillInBlankAnswerUseCase, UpdateSingleMultipleChoiceAnswerUseCase } from './answer';
import { UpdateFillInBlankCorrectAnswerUseCase, UpdateSingleMultipleChoiceCorrectAnswerUseCase } from './correct-answer';
import { WorkspaceSearchUseCase } from './workspace';
import { SearchSpaceObjectsUseCase } from './space';
import { GetGradesUseCase } from './grade';
import { GetSubjectsUseCase } from './subject';
import { GetGradesAndSubjectsUseCase } from './get-grades-and-subjects';
export * from './form';
export * from './folder';
export * from './section';
export * from './question';
export * from './answer';
export * from './workspace';
export * from './correct-answer';
export * from './grade';
export * from './subject';
export * from './get-grades-and-subjects';
export * from './space';
export var formUseCases = [CreateFormUseCase, DeleteFormUseCase, FindFormByIdUseCase, UpdateFormUseCase, CreateFolderUseCase, DeleteFolderUseCase, UpdateFolderUseCase, CreateSectionUseCase, UpdateSectionUseCase, DeleteSectionUseCase, UpdateQuestionUseCase, DeleteQuestionUseCase, CreateFillInBlankAnswerUseCase, WorkspaceSearchUseCase, DeleteFillInBlankAnswerUseCase, DeleteSingleMultipleChoiceAnswerUseCase, UpdateFillInBlankAnswerUseCase, UpdateSingleMultipleChoiceAnswerUseCase, UpdateFillInBlankCorrectAnswerUseCase, UpdateSingleMultipleChoiceCorrectAnswerUseCase, GetGradesUseCase, GetSubjectsUseCase, GetGradesAndSubjectsUseCase, SearchSpaceObjectsUseCase];