import { combineEpics } from 'redux-observable';
import { BehaviorSubject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { createEpicForUseCase } from '@lib/plugin-redux-core';
import { bootstrapAuthDI, bootstrapDI, UseCaseConfig } from '../di/index';
import { showToastHandler } from '@lib/common';

export const epic$ = new BehaviorSubject(combineEpics());
export const rootEpic = (action$: any, state$: any) =>
  epic$.pipe(mergeMap((epic) => epic(action$, state$, null)));

const cachedUseCases: UseCaseConfig[] = [];
const cachedAuthUseCases: UseCaseConfig[] = [];

export const runAsyncEpics = async () => {
  if (cachedUseCases.length > 0) {
    return;
  }
  const useCases = await bootstrapDI();
  cachedUseCases.push(...useCases);
  const epics = cachedUseCases.map((u: UseCaseConfig) => {
    return createEpicForUseCase(u.instance, u.name, showToastHandler);
  });
  epics.forEach((e) => epic$.next(e));
};

export const runAuthAsyncEpics = async () => {
  if (cachedAuthUseCases.length > 0) {
    return;
  }
  const useCases = await bootstrapAuthDI();
  cachedAuthUseCases.push(...useCases);
  const epics = cachedAuthUseCases.map((u: UseCaseConfig) => {
    return createEpicForUseCase(u.instance, u.name, showToastHandler);
  });
  epics.forEach((e) => epic$.next(e));
};
