import { combineReducers } from 'redux';
import { userReducer } from './user';
import { authReducer } from './auth';
import {
  AssignmentDetailPageState,
  assignmentDetailReducer,
} from './assignment-detail';
import { sidebarReducer } from './sidebar';
import {
  participatedAssignmentReducer,
  ParticipatedAssignmentState,
} from './participated-assignment';
import { SubmissionPageState, submissionReducer } from './submission';
import { pageLayoutReducer, PageLayoutState } from './layout';
import { toastReducer, ToastState } from './toast';
import {
  SubmissionPreviewPageState,
  submissionPreviewReducer,
} from './submission-preview';

export type RootReducerState = {
  //Todo: Refactor type for each reducer has any type
  auth: any;
  user: any;
  pageLayout: PageLayoutState;
  toast: ToastState;
  assignmentDetailPage: AssignmentDetailPageState;
  submissionPage: SubmissionPageState;
  sidebar: any;
  participatedAssignmentPage: ParticipatedAssignmentState;
  submissionPreviewPage: SubmissionPreviewPageState;
};

export const rootReducer = combineReducers<RootReducerState>({
  auth: authReducer,
  user: userReducer,
  toast: toastReducer,
  pageLayout: pageLayoutReducer,
  assignmentDetailPage: assignmentDetailReducer,
  submissionPage: submissionReducer,
  submissionPreviewPage: submissionPreviewReducer,
  sidebar: sidebarReducer,
  participatedAssignmentPage: participatedAssignmentReducer,
});
