import { useEffect, useLayoutEffect, useRef } from "react";
var useIsomorphicLayoutEffect = true ? useLayoutEffect : useEffect;
var isBrowser = true;
var zeroPosition = {
  x: 0,
  y: 0
};

var getClientRect = function getClientRect(element) {
  return element === null || element === void 0 ? void 0 : element.getBoundingClientRect();
};

var getScrollPosition = function getScrollPosition(_ref) {
  var element = _ref.element,
      useWindow = _ref.useWindow,
      boundingElement = _ref.boundingElement;

  if (!isBrowser) {
    return zeroPosition;
  }

  if (useWindow) {
    return {
      x: window.scrollX,
      y: window.scrollY
    };
  }

  var targetPosition = getClientRect((element === null || element === void 0 ? void 0 : element.current) || document.body);
  var containerPosition = getClientRect(boundingElement === null || boundingElement === void 0 ? void 0 : boundingElement.current);

  if (!targetPosition) {
    return zeroPosition;
  }

  return containerPosition ? {
    x: (containerPosition.x || 0) - (targetPosition.x || 0),
    y: (containerPosition.y || 0) - (targetPosition.y || 0)
  } : {
    x: targetPosition.left,
    y: targetPosition.top
  };
};

export var useScrollPosition = function useScrollPosition(effect, deps, element, useWindow, wait, boundingElement) {
  var position = useRef(getScrollPosition({
    useWindow: useWindow,
    boundingElement: boundingElement
  }));
  var throttleTimeout = null;

  var callBack = function callBack() {
    var currPos = getScrollPosition({
      element: element,
      useWindow: useWindow,
      boundingElement: boundingElement
    });
    effect({
      prevPos: position.current,
      currPos: currPos
    });
    position.current = currPos;
    throttleTimeout = null;
  };

  useIsomorphicLayoutEffect(function () {
    if (!isBrowser) {
      return undefined;
    }

    var handleScroll = function handleScroll() {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = window.setTimeout(callBack, wait);
        }
      } else {
        callBack();
      }
    };

    if (boundingElement) {
      var _boundingElement$curr;

      (_boundingElement$curr = boundingElement.current) === null || _boundingElement$curr === void 0 ? void 0 : _boundingElement$curr.addEventListener("scroll", handleScroll, {
        passive: true
      });
    } else {
      window.addEventListener("scroll", handleScroll, {
        passive: true
      });
    }

    return function () {
      if (boundingElement) {
        var _boundingElement$curr2;

        (_boundingElement$curr2 = boundingElement.current) === null || _boundingElement$curr2 === void 0 ? void 0 : _boundingElement$curr2.removeEventListener("scroll", handleScroll);
      } else {
        window.removeEventListener("scroll", handleScroll);
      }

      if (throttleTimeout) {
        clearTimeout(throttleTimeout);
      }
    };
  }, deps);
};
useScrollPosition.defaultProps = {
  deps: [],
  element: false,
  useWindow: false,
  wait: null,
  boundingElement: false
};